import Vue from "vue";
import VueCookies from "vue-cookies";
import VueTheMask from "vue-the-mask";
import BootstrapVue from "bootstrap-vue";
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";
import VueObserveVisibility from "vue-observe-visibility";
import App from "./App.vue";
import router from "./router";
import "setimmediate";
import Sentry from "@/js/Sentry";

import "@/registerIcons";

import "@/assets/scss/beta/components/page-loader.scss";

Vue.config.productionTip = false;

Sentry.setupSentry();

// Libraries

Vue.use(VueCookies);
Vue.use(VueTheMask);
Vue.use(BootstrapVue);
Vue.use(VueObserveVisibility);

// eslint-disable-next-line
Vue.component("font-awesome-icon", FontAwesomeIcon);
// eslint-disable-next-line
Vue.component("font-awesome-layers", FontAwesomeLayers);
// eslint-disable-next-line
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);

new Vue({
    router,
    render: h => h(App)
}).$mount("#app");

