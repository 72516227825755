<template>
    <header>
        <b-container class="align-items-center py-3 px-3">
            <b-row align-v="center">
                <div id="zixty-logo" class="col-6 col-md-5 col-lg-6 mb-5">
                    <a href="https://www.zixty.com">
                        <b-img alt="Zixty logo" fluid src="img/zixty_logo.png" />
                    </a>
                </div>
            </b-row>
            <b-row>
                <div class="col-12">
                    <h1 class="quick-quote mb-3 mb-sm-5 text-center text-md-left">
                        Quick Quote
                    </h1>
                </div>
            </b-row>
        </b-container>
    </header>
</template>

<script>
export default {
    name: "Header",
    components: {
        //
    },
    props: {
        showButtons: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data() {
        return {};
    },
    methods: {
    }
};
</script>

<style lang='scss' scoped>
#zixty-logo {
    img {
        max-height: 40px;
    }
}

@media (min-width: 576px) {
    #zixty-logo {
        img {
            max-height: 77px;
        }
    }
    .quick-quote {
        font-size: 2.95rem;
    }
}
</style>
