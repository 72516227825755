<template>
    <footer>
        <div class="body-footer position-absolute h-auto">
            <div class="container">
                <div class="row my-3">
                    <div class="col-12 d-flex flex-column flex-md-row">
                        <div class="col-md-3">
                            <FooterLinksList
                                key="about"
                                :title="lists.about.title"
                                :links="lists.about.links"
                            />
                        </div>
                        <div class="col-md-3">
                            <FooterLinksList
                                key="help"
                                :title="lists.help.title"
                                :links="lists.help.links"
                            />
                        </div>
                        <div class="col-md-3">
                            <FooterLinksList
                                key="compliance"
                                :title="lists.compliance.title"
                                :links="lists.compliance.links"
                            />
                        </div>
                        <div class="col-md-3">
                            <div>
                                <div class="my-3 text-white">
                                    <h2 class="text-white font-weight-light">
                                        {{ lists.socials.title }}
                                    </h2>
                                </div>
                                <div>
                                    <div class="d-flex flex-row my-2">
                                        <div v-for="social in lists.socials.links"
                                            :key="social.network"
                                            class="mr-4"
                                            :title="'Zixty ' + social.network"
                                        >
                                            <b-link :href="social.href" target="_blank" rel="noopener" class="text-green-dark">
                                                <i :class="social.icon" />
                                            </b-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 d-flex flex-column flex-md-row align-items-end">
                        <div class="col-md-8">
                            <div class="d-flex flex-column flex-md-row">
                                <div
                                    v-for="link in lists.general.links"
                                    :key="link.text"
                                    class="my-3 mr-4"
                                >
                                    <b-link :href="link.href" target="_blank" rel="noopener" class="text-green-dark">
                                        {{ link.text }}
                                    </b-link>
                                </div>
                            </div>
                        </div>
                        <div id="footer-logo" class="col-md-4">
                            <div class="text-center text-md-right">
                                <img src="/img/zixty_logo.png" title="Zixty" alt="Zixty Logo" />
                            </div>
                            <div class="text-center text-md-right">
                                <div class="my-3 text-white">
                                    <h2>
                                        Insurance for Now
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 d-flex flex-column flex-md-row">
                        <div class="col-md-6 my-3 text-gray">
                            <span>
                                © All rights reserved. Zixty Ltd is registered in England No. 13404538. Registered Office: 10 York Road, London, United Kingdom, SE1 7ND.
                            </span>
                        </div>
                        <div class="col-md-6 my-3 text-gray">
                            <span>
                                Zixty Ltd is an Appointed Representative of Wrapper Limited which is authorised and regulated by the Financial Conduct Authority (FRN.936006).
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row my-3">
                    <div class="d-flex flex-row justify-content-center">
                        <div class="col-12 text-gray">
                            <span>
                                v{{ getVersion }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import FooterLinksList from "@/components/FooterLinksList";

const createSocialLinkEntry = (network, icon, href) => ({ network, icon, href });
const createListLinkEntry = (text, href) => ({ text, href });

export default {
    name: "Footer",
    components: {
        FooterLinksList,
    },
    data() {
        return {
            lists: {
                about: {
                    title: "About",
                    links: [
                        createListLinkEntry("Home", "https://www.zixty.com/"),
                        createListLinkEntry("About Zixty", "https://www.zixty.com/about-zixty/"),
                        createListLinkEntry("Carbon Offsetting", "https://www.zixty.com/carbon-offsetting/"),
                        createListLinkEntry("Zixty Miles", "https://www.zixty.com/zixty-miles/"),
                        createListLinkEntry("The Company", "https://www.zixty.com/the-company/"),
                        createListLinkEntry("Careers", "https://www.zixty.com/careers/"),
                    ],
                },
                help: {
                    title: "Help",
                    links: [
                        createListLinkEntry("FAQs", "https://www.zixty.com/faqs/"),
                        createListLinkEntry("Contact", "https://www.zixty.com/contact/"),
                        createListLinkEntry("Zixty Blog", "https://www.zixty.com/blog/"),
                        createListLinkEntry("Public Relations", "https://www.zixty.com/public-relations/"),
                        createListLinkEntry("Partnerships", "https://www.zixty.com/partnerships/"),

                    ],
                },
                compliance: {
                    title: "Compliance",
                    links: [
                        createListLinkEntry("Complaints", "https://www.zixty.com/complaints/"),
                        createListLinkEntry("Terms of Business", "https://www.zixty.com/terms-of-business/"),
                    ],
                },
                socials: {
                    title: "Follow Us",
                    links: [
                        createSocialLinkEntry("Facebook", "fa fa-2x fa-facebook", "https://www.facebook.com/ZixtyInsure"),
                        createSocialLinkEntry("Instagram", "fa fa-2x fa-instagram", "https://www.instagram.com/zixtygo/"),
                        createSocialLinkEntry("Twitter", "fa fa-2x fa-twitter", "https://www.twitter.com/zixtygo"),
                        createSocialLinkEntry("LinkedIn", "fa fa-2x fa-linkedin", "https://www.linkedin.com/company/zixty/"),
                    ],
                },
                general: {
                    title: "",
                    links: [
                        createListLinkEntry("Cookie Policy", "https://www.zixty.com/cookie-policy/"),
                        createListLinkEntry("Privacy Notice", "https://www.zixty.com/privacy-policy/"),
                        createListLinkEntry("Terms & Conditions", "https://www.zixty.com/terms-and-conditions/"),
                        createListLinkEntry("Accessibility", "https://www.zixty.com/accessibility/"),
                    ],
                },
            },
        };
    },
    computed: {
        getVersion: function() {
            return process.env.VUE_APP_PORTAL_VERSION;
        }
    },
    methods: {
    }
};
</script>

<style lang="scss" scoped>
    #footer-logo {
        img {
            width: 271px;
        }
        h2 {
            font-size: 31px;
        }
    }
    a {
        font-weight: 500;
        font-size: 1.35em;
    }
</style>
