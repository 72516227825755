<template>
    <div id="sidebar-full-app">
        <div class="row h-100">
            <div class="my-auto px-5 mx-auto text-center">
                <div class="my-3 my-md-4 my-lg-5">
                    <h4>Get started faster!</h4>
                </div>
                <div class="my-3 my-md-4 my-lg-5">
                    <p>Download our app to get an accurate price tailored <br /> just for you and buy the policy there and then.</p>
                </div>
                <div id="app-logos">
                    <div class="my-3 my-md-4 my-lg-5">
                        <b-link href="https://app.zixty.com/go" target="_blank" rel="noopener">
                            <img src="/img/app-store-icon-apple.png" />
                        </b-link>
                    </div>
                    <!-- <div class="my-3 my-md-4 my-lg-5">
                        <b-link href="https://app.zixty.com/go" target="_blank" rel="noopener">
                            <img src="/img/app-store-icon-google.png" />
                        </b-link>
                    </div> -->
                </div>
                <div class="my-3 my-md-4 my-lg-5">
                    <div>
                        <p>
                            Scan our QR code with your camera phone
                        </p>
                    </div>
                    <div id="qr">
                        <a href="https://app.zixty.com/go" target="_blank" rel="noopener">
                            <img src="/img/qr.png" class="img-thumbnail" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SidebarFullApp",
    props: {
        //
    },
    data() {
        return {
            //
        };
    },
};
</script>

<style lang="scss" scoped>
#sidebar-full-app {
    top: 0px;
    bottom: 0px;
    position: fixed;
    overflow: hidden;
    background: #95c11f;
    background-image: linear-gradient(to right, #bccf01 , #52ae32);
    color: #414042;
    #app-logos {
        img {
            width: 180px;
        }
    }
    #qr {
        img {
            width: 180px;
        }
    }
}
</style>
