import winston from "winston";
import SentryTransporter from "@/js/SentryTransporter";


export default {
    install(Vue, _options) {
        const logger = winston.createLogger({
            format: winston.format.json(),
            transports: [
                new winston.transports.Console({level: "debug"}),
                new SentryTransporter({ format: winston.format.json(), level: "warn" })]
        });

        Vue.prototype.$logger = logger;
    }
};
