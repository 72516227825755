<template>
    <div class="holder">
        <div class="body-content d-flex flex-column">
            <div id="app">
                <router-view />
            </div>
            <div class="body-footer-push" />
        </div>
        <Footer />
        <div v-show="inProgress" id="view-loader">
            <em class="fa fa-circle-o-notch fa-spin fa-2x fa-fw" />
        </div>
    </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
    name: "App",
    components: {
        Footer,
    },
    mixins: [],
    data() {
        return {
            inProgress: false,
        };
    },
    computed: {
    },
    mounted() {
    },

    methods: {
    },
};
</script>

<style lang="css">
    @import "../node_modules/bootstrap-vue/dist/bootstrap-vue.css";
</style>

<style lang="scss">
    // TODO: Can we use reboot only?
    @import "@/assets/scss/vendor/bootstrap/scss/bootstrap.scss";
    @import "@/assets/scss/components/_layout.scss";

    .notifications {
        bottom: 100px !important;
    }

    .holder {
        height: 100% !important;
    }
</style>
