<template>
    <div>
        <b-button
            v-b-modal="getModalName"
            :variant="actionVariant"
            :class="{
                'rounded-pill opacity-button': actionVariant === 'button'
            }"
        >
            {{ actionLabel }}
        </b-button>

        <b-modal
            :id="getModalName"
            size="md"
            scrollable
            centered
            ok-only
            title="ZIXTY INSURANCE - INDICATIVE QUOTE ASSUMPTIONS"
        >
            <div class="m-3">
                <p>(V1.0 - 11th October 2022)</p>

                <p>To give an indicative quote we've made some general assumptions so we can generate a price. If you like the price and look to proceed we will need to gather a few more bits of information which may change the price from the indicative quote provided.</p>

                <p>We have made these assumptions to generate the indicative quote:</p>

                <ul>
                    <li>An assumed average value for your vehicle that may be different to the current market value</li>
                    <li>You are in an industry acceptable occupation</li>
                    <li>You are a UK resident and have held a full UK driving licence for at least 12 months</li>
                    <li>You are in good standing with credit reference agencies</li>
                </ul>

                <p>In addition, if you do look to buy a policy there are some assumptions and conditions that you should be aware of with all of our policies. We cannot help you if:</p>

                <ul>
                    <li>You had more than 2 claims irrespective of blame within 3 years of cover date</li>
                    <li>You have more than 6 declarable penalty points endorsed on your licence</li>
                    <li>You have been banned from driving or had your licence revoked within 5 years of the cover date</li>
                    <li>You have pending motoring convictions/prosecutions pending that will trigger the above</li>
                    <li>You have criminal convictions not spent under the rehabilitation of offenders act</li>
                    <li>You have had previous insurance policies cancelled/voided/refused</li>
                    <li>You have medical conditions that have not been declared to the DVLA where required</li>
                    <li>Your address is a mobile home/caravan site/houseboat or any other non fixed abode</li>
                    <li>The vehicle is modified (other than manufacturer options/tow bar/modified for disability)</li>
                    <li>The vehicle will be used for anything other than social domestic and pleasure/commuting/business use only</li>
                    <li>The vehicle has more than 7 seats as manufactured</li>
                    <li>The vehicle will be used to carry hazardous/corrosive/explosive goods  </li>
                    <li>The vehicle will be used for any type of delivery or taxi service</li>
                    <li>The vehicle is not right hand drive, not registered in the UK and/or does not have a valid MOT</li>
                    <li>The vehicle has been declared a Cat A/B write off</li>
                    <li>The vehicle is being exported and will be used outside of the UK other than for leisure purposes</li>
                </ul>

                <p>Please note that:</p>
                <ul>
                    <li><strong>Your policy will not provide stand alone windscreen or glass cover </strong></li>
                    <li><strong>Your policy will not cover driving of other vehicles</strong></li>
                    <li><strong>Your policy will not cover release of the vehicle from an impound centre</strong></li>
                    <li><strong>Your policy will not cover rented/hire vehicles of any description</strong></li>
                    <li><strong>No amendments/alterations or changes can be made once cover is in place</strong></li>
                </ul>
            </div>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: "QuoteAssumptions",
    props: {
        actionVariant: {
            type: String,
            default: "button",
        },
        actionLabel: {
            type: String,
            default: "Show Assumptions",
        },
    },
    data() {
        return {
            //
        };
    },
    computed: {
        getWidthStyle: function () {
            return `width: ${window.innerWidth * 0.8}`
        },
        getModalName: function () {
            return "modal-assumptions-" + (new Date().getTime());
        },
    },
    methods: {
        //
    }
};
</script>
