<template>
    <div class="col-12 mt-4">
        <div class="row">
            <div class="col-12 text-center">
                <h5 class="font-weight-400">
                    Get started now!
                </h5>
            </div>
        </div>
        <div class="row">
            <div id="app-logos" class="mx-auto">
                <div class="my-2">
                    <b-link href="https://app.zixty.com/go" target="_blank" rel="noopener">
                        <img src="/img/app-store-icon-apple.png" />
                    </b-link>
                </div>
                <!-- <div class="my-2">
                    <b-link href="https://app.zixty.com/go" target="_blank" rel="noopener">
                        <img src="/img/app-store-icon-google.png" />
                    </b-link>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GetStartedNowOnMobile",
    props: {
        //
    },
    data() {
        return {
            //
        };
    },
};
</script>

<style lang="scss" scoped>
#app-logos {
    img {
        width: 100%;
        max-width: 220px;
    }
}
</style>
