const MAIN_CLASS = "inz-page-loader";

export default {
    init (containingElement=null) {
        if (!document.body || document.getElementsByClassName(MAIN_CLASS).length) return;

        const PAGE_LOADER_ELEMENT = document.createElement("div");

        PAGE_LOADER_ELEMENT.classList.add(MAIN_CLASS);

        if (containingElement) {
            containingElement.append(PAGE_LOADER_ELEMENT);
        } else {
            document.body.append(PAGE_LOADER_ELEMENT);
        }

    },

    remove () {
        const PAGE_LOADER = document.getElementsByClassName(MAIN_CLASS)[0];

        if (PAGE_LOADER) {
            PAGE_LOADER.parentNode.removeChild(PAGE_LOADER);
        }
    }
};
