// TODO: Remove it and use <i class="fa" /> instead

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faUserSecret,
    faCog,
    faCheck,
    faLock,
    faUnlock,
    faUser,
    faUserPlus,
    faPaperclip,
    faRedo,
    faRedoAlt,
    faAngleDoubleUp,
    faSignOutAlt,
    faUsers,
    faCopy,
    faStopwatch,
    faGlasses,
    faSearch,
    faCircle,
    faExclamationTriangle,
    faExclamationCircle,
    faFilePdf,
    faImage,
    faCheckCircle,
    faEdit,
    faQuestionCircle,
    faFileContract,
    faInfoCircle,
    faPlus,
    faCircleXmark
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faUserSecret,
    faCog,
    faCheck,
    faLock,
    faUnlock,
    faUser,
    faUserPlus,
    faPaperclip,
    faRedo,
    faRedoAlt,
    faAngleDoubleUp,
    faSignOutAlt,
    faUsers,
    faCopy,
    faStopwatch,
    faGlasses,
    faSearch,
    faCircle,
    faExclamationTriangle,
    faExclamationCircle,
    faFilePdf,
    faImage,
    faCheckCircle,
    faEdit,
    faQuestionCircle,
    faFileContract,
    faInfoCircle,
    faPlus,
    faCircleXmark
);
