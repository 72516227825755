<template>
    <div class="px-2 px-md-0 mx-auto col-12 col-md-10 col-lg-8">
        <div class="row my-2 mb-4 pt-md-3">
            <div class="col-12 col-md-10 col-lg-8 text-center">
                <h4>Get a price in just 60 seconds</h4>
            </div>
        </div>

        <div ref="alert">
            <b-alert v-if="quoteStatus !== null && quoteStatus !== 'OK'" show variant="danger">
                {{ quoteMessageDisplay }}
            </b-alert>
        </div>

        <b-form v-if="displayForm" show @submit="onSubmit" @reset="onReset">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 col-lg-8">
                        <b-form-group
                            id="input-group-vehicle-reg"
                            label="What car do you need cover for?"
                            label-for="input-vehicle-reg"
                            :label-size="sizing"
                        >
                            <b-form-input
                                id="input-vehicle-reg"
                                v-model="form.vehicleReg"
                                class="data-hj-allow"
                                placeholder="Enter Vehicle Registration"
                                aria-describedby="input-live-feedback-vehicle-reg"
                                required
                                :size="sizing"
                                :state="vehicleRegState"
                                trim
                                :formatter="upperCaseFormatter"
                                @input="vehicleRegChange"
                            />
                            <b-form-invalid-feedback id="input-live-feedback-vehicle-reg">
                                {{ vehicleRegError }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-10 col-lg-8">
                        <b-form-group
                            id="input-group-loc"
                            label="How long do you need it for?"
                            label-for="input-length-of-cover"
                            :label-size="sizing"
                        >
                            <b-form-text id="input-live-help-loc" text-variant="black">
                                <p>From 1 hour to 28 days</p>
                            </b-form-text>
                            <b-input-group class="mb-2">
                                <b-form-input
                                    id="input-length-of-cover"
                                    v-model="form.lengthOfCover"
                                    class="data-hj-allow"
                                    placeholder="Enter a number..."
                                    aria-describedby="input-live-help-loc input-live-feedback-loc"
                                    required
                                    :size="sizing"
                                    type="number"
                                    min="1"
                                    trim
                                    :max="durationMax"
                                    :state="lengthOfCoverState"
                                    @input="lengthOfCoverChange"
                                />
                                <b-input-group-append>
                                    <b-form-select
                                        id="input-length-of-cover-type"
                                        v-model="form.lengthOfCoverUnit"
                                        class="data-hj-allow"
                                        required
                                        :size="sizing"
                                        :options="lengthOfCoverUnitOptions"
                                        aria-label="Length of Cover Duration:"
                                    />
                                </b-input-group-append>

                                <b-form-invalid-feedback id="input-live-feedback-loc">
                                    {{ lengthOfCoverError }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-10 col-lg-8">
                        <div ref="visibility" v-observe-visibility="visibilityChanged" class="d-none d-lg-block">
                            <span>&nbsp;</span>
                        </div>
                        <b-form-group
                            id="input-group-start-date"
                            label="When do you want it to start?"
                            label-for="input-start-date"
                            :label-size="sizing"
                        >
                            <div class="row">
                                <div class="w-100">
                                    <b-input-group>
                                        <b-form-input
                                            id="input-start-date"
                                            v-model="form.startDate"
                                            v-mask="'##/##/####'"
                                            class="data-hj-allow"
                                            type="text"
                                            placeholder="DD/MM/YYYY"
                                            autocomplete="off"
                                            :state="startDateState"
                                            debounce="500"
                                            required
                                            trim
                                            :size="sizing"
                                            aria-describedby="input-live-feedback-start-date"
                                            @input="startDateChange"
                                        />
                                        <b-input-group-append>
                                            <b-form-datepicker
                                                id="input-start-date-picker"
                                                v-model="form.startDateIso"
                                                type="date"
                                                :size="sizing"
                                                button-only
                                                right
                                                locale="en-GB"
                                                aria-controls="input-start-date"
                                                :min="minStartDate"
                                                :max="maxStartDate"
                                                @input="startDateIsoChange"
                                            />
                                        </b-input-group-append>
                                        <b-form-invalid-feedback id="input-live-feedback-start-date">
                                            {{ startDateError }}
                                        </b-form-invalid-feedback>
                                    </b-input-group>
                                </div>
                                <div class="d-none">
                                    <b-input-group>
                                        <b-form-input
                                            id="input-start-time"
                                            v-model="form.startTime"
                                            type="text"
                                            placeholder="HH:MI"
                                            autocomplete="off"
                                            :state="startTimeState"
                                            debounce="100"
                                            required
                                            trim
                                            :size="sizing"
                                            :formatter="removeSecondsFormatter"
                                            aria-describedby="input-live-feedback-start-time"
                                        />
                                        <b-input-group-append>
                                            <b-form-timepicker
                                                id="input-start-time-picker"
                                                v-model="form.startTime"
                                                type="time"
                                                :size="sizing"
                                                button-only
                                                right
                                                locale="en-GB"
                                                minutes-step="5"
                                                aria-controls="input-start-date"
                                                @input="timeInputChanged"
                                            />
                                        </b-input-group-append>
                                        <b-form-invalid-feedback id="input-live-feedback-start-time">
                                            {{ startTimeError }}
                                        </b-form-invalid-feedback>
                                    </b-input-group>
                                </div>
                            </div>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-10 col-lg-8">
                        <b-form-group
                            id="input-group-first-name"
                            label="First name"
                            label-for="input-first-name"
                            :label-size="sizing"
                        >
                            <b-form-input
                                id="input-first-name"
                                v-model="form.firstName"
                                placeholder="Enter first name"
                                required
                                :size="sizing"
                                trim
                                aria-describedby="input-live-feedback-first-name"
                                :state="firstNameState"
                                @input="firstNameChange"
                            />
                            <b-form-invalid-feedback id="input-live-feedback-first-name">
                                {{ firstNameError }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-10 col-lg-8">
                        <b-form-group
                            id="input-group-last-name"
                            label="Last name"
                            label-for="input-last-name"
                            :label-size="sizing"
                        >
                            <b-form-input
                                id="input-last-name"
                                v-model="form.lastName"
                                placeholder="Enter last name"
                                required
                                :size="sizing"
                                :state="lastNameState"
                                trim
                                aria-describedby="input-live-feedback-last-name"
                                @input="lastNameChange"
                            />
                            <b-form-invalid-feedback id="input-live-feedback-last-name">
                                {{ lastNameError }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-10 col-lg-8">
                        <b-form-group
                            id="input-group-date-of-birth"
                            label="Date of birth"
                            label-for="input-date-of-birth"
                            :label-size="sizing"
                        >
                            <b-input-group class="mb-2">
                                <b-form-input
                                    id="input-date-of-birth"
                                    v-model="form.dateOfBirth"
                                    v-mask="'##/##/####'"
                                    class="data-hj-allow"
                                    type="text"
                                    placeholder="DD/MM/YYYY"
                                    autocomplete="off"
                                    :state="dateOfBirthState"
                                    aria-describedby="input-live-help-dob input-live-feedback-dob"
                                    debounce="100"
                                    required
                                    trim
                                    :size="sizing"
                                    @input="dateOfBirthChange"
                                />
                                <b-input-group-append>
                                    <b-form-datepicker
                                        id="input-date-of-birth-picker"
                                        v-model="form.dateOfBirthIso"
                                        type="date"
                                        :size="sizing"
                                        button-only
                                        right
                                        locale="en-GB"
                                        aria-controls="input-date-of-birth"
                                        :min="minDateOfBirth"
                                        :max="maxDateOfBirth"
                                        @input="dateOfBirthIsoChange"
                                    />
                                </b-input-group-append>
                                <b-form-invalid-feedback id="input-live-feedback-dob">
                                    {{ dateOfBirthError }}
                                </b-form-invalid-feedback>
                            </b-input-group>

                            <b-form-text id="input-live-help-dob" text-variant="black">
                                We can only insure you if you are 21 or over and not older than 75
                            </b-form-text>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-10 col-lg-8">
                        <b-form-group
                            id="input-group-postcode"
                            label="Address"
                            label-for="input-postcode"
                            :label-size="sizing"
                        >
                            <b-form-text id="input-group-postcode" text-variant="black">
                                <p>Click Lookup to find address</p>
                            </b-form-text>
                            <b-input-group class="mb-2">
                                <b-form-input
                                    id="input-postcode"
                                    v-model="form.postcode"
                                    class="data-hj-allow"
                                    type="text"
                                    autocomplete="off"
                                    :state="postcodeState"
                                    debounce="500"
                                    required
                                    trim
                                    aria-describedby="input-live-feedback-postcode"
                                    :size="sizing"
                                    :formatter="upperCaseFormatter"
                                    placeholder="Enter your postcode"
                                    @input="postcodeChange"
                                />
                                <b-button :disabled="!postcodeState" variant="primary" class="ml-2 rounded-pill opacity-button-small text-white" @click="getAddressesForPostcode">
                                    Lookup
                                </b-button>
                                <b-form-invalid-feedback id="input-live-feedback-postcode">
                                    {{ postcodeError }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                            <b-form-select
                                v-if="postcodeState"
                                id="input-length-of-cover-type"
                                v-model="form.address"
                                required
                                :size="sizing"
                                :state="addressState"
                                :options="foundAddressSelectList"
                                aria-describedby="input-live-feedback-address"
                                @input="addressChange"
                            />
                            <b-form-invalid-feedback id="input-live-feedback-address">
                                {{ addressError }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-10 col-lg-8">
                        <b-form-group
                            id="input-group-1"
                            label="Email address"
                            label-for="input-email"
                            :label-size="sizing"
                        >
                            <b-form-text id="input-live-help-email" text-variant="black">
                                <p>
                                    We'll send a copy of the quote to your email address and invite you to the app
                                </p>
                            </b-form-text>

                            <b-form-input
                                id="input-email"
                                v-model="form.email"
                                type="email"
                                placeholder="Enter email"
                                aria-describedby="input-live-help-email input-live-feedback-email"
                                required
                                :size="sizing"
                                :formatter="lowerCaseFormatter"
                                debounce="500"
                                :state="emailState"
                                autocomplete="on"
                                trim
                                @input="emailChange"
                            />

                            <b-form-invalid-feedback id="input-live-feedback-email">
                                {{ emailError }}
                            </b-form-invalid-feedback>

                            <div v-show="emailState===true" style="padding-top: 10px;">
                                <b-form-input
                                    id="input-confirm"
                                    v-model="form.emailConfirm"
                                    type="text"
                                    aria-label="Confirm email"
                                    placeholder="Confirm email"
                                    aria-describedby="input-live-feedback-confirm"
                                    required
                                    :size="sizing"
                                    :formatter="lowerCaseFormatter"
                                    debounce="500"
                                    :state="emailConfirmState"
                                    trim
                                    autocomplete="off"
                                />

                                <b-form-invalid-feedback v-if="emailState===true" id="input-live-feedback-confirm">
                                    {{ emailConfirmError }}
                                </b-form-invalid-feedback>
                            </div>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-10 col-lg-8">
                        <b-form-group
                            id="input-group-marketing"
                            label=""
                            :label-size="sizing"
                        >
                            <b-input-group class="mb-2">
                                <div class="custom-control custom-checkbox b-custom-control-md">
                                    <input id="input-marketing" v-model="form.marketing" class="data-hj-allow custom-control-input" type="checkbox" />
                                    <label for="input-marketing" class="custom-control-label">
                                        <small class="form-text text-black" style="margin-top: 0px;">
                                            We'd like to send you marketing information including news, updates and special offers via email. Tick to opt-in. Opt-out anytime.
                                        </small>
                                    </label>
                                </div>
                            </b-input-group>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="row mb-5 text-center">
                <div class="col-12 col-md-10 col-lg-8">
                    <b-button type="submit" variant="primary" class="rounded-pill opacity-button">
                        Get My Quote
                    </b-button>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12 col-md-10 col-lg-8 text-center">
                    <div class="mx-auto">
                        <p>
                            We make assumptions to work out your indicative price. Additional answers are required when you buy a policy through the app which may affect your final price.
                        </p>
                        <p>
                            <QuoteAssumptions action-variant="link" action-label="See Assumptions" />
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mb-3 text-center">
                <div class="col-12 col-md-10 col-lg-8">
                    <b-button type="reset" variant="secondary" class="b-button-lighter-gray rounded-pill opacity-button">
                        Clear Form
                    </b-button>
                </div>
            </div>

            <div class="row d-md-none">
                <GetStartedNowOnMobile />
            </div>
        </b-form>
    </div>
</template>

<script>

import moment from "moment";
import { guessedTimezoneName, getLocalDateTimeInFromNaiveString } from "@/js/DisplayUtils";
import { camelCase } from "lodash";
import { logger } from "@sentry/utils";
import ApiService from "@/js/ApiService";
import PageLoader from "@/js/PageLoader";
import QuoteAssumptions from "@/components/QuoteAssumptions";
import GetStartedNowOnMobile from "@/components/GetStartedNowOnMobile";

export default {
    name: "QuoteForm",
    components: {
        QuoteAssumptions,
        GetStartedNowOnMobile,
    },
    props: {
        sizing: {
            type: String,
            required: true,
            default: "md"
        }
    },
    data() {
        return {
            displayForm: false,
            form: {
                email: null,
                emailConfirm: null,
                firstName: null,
                lastName: null,
                vehicleReg: null,
                startDate: null,
                startDateIso: null,
                startTime: null,
                lengthOfCover: null,
                lengthOfCoverUnit: "days",
                dateOfBirthIso: null,
                dateOfBirth: null,
                postcode: null,
                address: null,
                marketing: false,
            },
            formErrors: {
                lengthOfCover: null,
                vehicleReg: null,
                address: null,
                startDate: null,
                firstName: null,
                lastName: null,
                dateOfBirth: null,
                postcode: null,
                address: null,
                email: null,
            },
            lengthOfCoverUnitOptions: [
                { value: "days", text: "Days" },
                { value: "hours", text: "Hours" }
            ],
            vehicleRegEx: /^([A-Z]{2}[0-9]{2}\s{0,1}[A-Z]{3})$|^([A-Z]{1}[0-9]{1,3}\s{0,1}[A-Z]{3})$|^([A-Z]{3}\s{0,1}[0-9]{1,3}[A-Z]{1})$|^([A-Z]{1,3}\s{0,1}[0-9]{1,4})$|^([0-9]{1,4}\s{0,1}[A-Z]{1,3})$/,
            emailRegEx: /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}/,
            postcodeRegEx: /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z]))))\s{0,1}[0-9][A-Z]{2}$/,
            // Useful unicode ref = https://jrgraphix.net/r/Unicode/
            // this includes:
            // - space
            // - full stop
            // - apostrophe
            // - hyphen
            // - Upper and Lower Case from base Latin Unicode
            //     - SO FAR = \u0020\u0027\u002D-\u002E\u0041-\u005A\u0061-\u007A
            // - Letter like characters in Latin-1 Supplement
            //     - \u00C0-\u00FF
            // - All characters in Latin Extended A
            //     - \u0100-\u017F
            // - All characters in Latin Extended B
            //     - \u180-\u24F
            // - All characters in Latin Extended Additional
            //     - \u1E00-\u1EFF
            // Together:
            nameRegEx: /^[\u0020\u0027\u002D-\u002E\u0041-\u005A\u0061-\u007A\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF]+$/,
            foundAddresses: [],
            isLarge: false,
            quote: null,
            quoteStatus: null,
            quoteMessage: null,
            innerHeight: null,
            quoteErrorMap: {
                TOO_BUSY: "Sorry but our systems are busy right now… Please try again in a short while or talk to us via chat on our web site."
            }

        };
    },
    computed: {
        quoteMessageDisplay() {
            if (this.quoteMessage) {
                return this.quoteErrorMap[this.quoteMessage] ? this.quoteErrorMap[this.quoteMessage] : this.quoteMessage;
            } else {
                return null;
            }
        },
        durationMax: function () {
            return this.form.lengthOfCoverUnit === "hours" ? 24 : 28
        },
        vehicleRegState: function () {
            return this.form.vehicleReg === null ? null : this.vehicleRegError === null;
        },
        vehicleRegError: function() {
            if (this.form.vehicleReg === null) {
                return null;
            } else {
                const result = this.vehicleRegEx.exec(this.form.vehicleReg);

                if (result === null) {
                    return "Vehicle Registration is not in a valid format"
                }

                return this.formErrors.vehicleReg;
            }
        },
        lengthOfCoverState: function () {
            return this.form.lengthOfCover === null ? null : this.lengthOfCoverError === null;
        },
        lengthOfCoverError: function() {
            if (this.form.lengthOfCover === null) {
                return null;
            } else {
                const lengthOfCoverNumber = Number(this.form.lengthOfCover);
                if ((lengthOfCoverNumber <= 0 || lengthOfCoverNumber > this.durationMax || !Number.isInteger(lengthOfCoverNumber))) {
                    return "Length of Cover is Invalid";
                }
                return this.formErrors.lengthOfCover;
            }
        },
        emailState: function () {
            if (this.form.email === null) {
                return null;
            } else {
                return this.emailError === null;
            }
        },
        emailConfirmState: function () {
            if (this.emailState === null || this.form.emailConfirm === null) {
                return null;
            } else {
                return this.form.emailConfirm == this.form.email;
            }
        },
        emailError: function() {
            if (this.form.email === null) {
                return null;
            } else {
                if (!this.emailRegEx.test(this.form.email)) {
                    return "Email address does not appear to be valid";
                }

                if (this.form.email.indexOf("+") !== -1) {
                    return "We do not accept email aliases";
                };

                return this.formErrors.email;
            }

        },
        emailConfirmError: function() {
            if (this.emailState === null || this.form.emailConfirm === null) {
                return null;
            } else {
                if (this.form.emailConfirm != this.form.email) {
                    return "Email Confirmation does not match"
                }
                return null;
            }
        },
        startDateLabel: function() {
            return this.isLarge ? "Start Date & Time" : "Start Date";
        },
        startTimeLabel: function() {
            return this.isLarge ? "" : "Start Time";
        },
        startDateTime: function() {
            const startDateTimeStr = `${this.form.startDateIso}T${this.form.startTime}:00`
            const dt = this.form.startDate && this.form.startTime ? getLocalDateTimeInFromNaiveString(startDateTimeStr, this.guessedTimezoneName) : null;
            return dt;
        },
        minStartDate: function() {
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            const minDate = new Date(today)
            return minDate
        },
        maxStartDate: function() {
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            const maxDate = new Date(today)
            maxDate.setDate(maxDate.getDate() + 28);
            return maxDate
        },
        startDateState: function () {
            if (this.form.startDate === null || this.form.startDate.length < 10) {
                return null;
            } else {
                return this.startDateError === null;
            }
        },
        startDateError: function() {
            if (this.form.startDate === null || this.form.startDate.length < 10) {
                return null;
            } else {
                if (this.form.startTime.length > 10) {
                    return "Start date is not in the correct format: DD/MM/YYYY";
                }

                const separator1 = this.form.startDate.substring(2, 3);
                const separator2 = this.form.startDate.substring(5, 6);
                if (separator1 !== "/" || separator2 !== "/") {
                    return "Please use a forward slash \"/\" to separate day, month and year";
                }

                const splits = this.form.startDate.split("/")

                if (splits.length !== 3 || splits[0].length !== 2 || splits[1].length !== 2 || splits[2].length !== 4) {
                    return "Start date is not in the correct format: DD/MM/YYYY";
                }

                let momentObj;
                try {
                    momentObj = this.parseDate("startDate", "DD/MM/YYYY");
                    if (momentObj && !momentObj.isValid()) {
                        return "Start Date is not a valid date";
                    }

                    if (momentObj.isAfter(this.maxStartDate) || momentObj.isBefore(this.minStartDate)) {
                        return "Start Date is in the past or more than 28 days in the future";
                    }

                } catch(e) {
                    return "Start Date is not a valid date";
                }

                return this.formErrors.startDate;
            }
        },
        startTimeState: function() {
            if (this.form.startTime === null || this.form.startTime.length < 5) {
                return null;
            } else {
                return this.startTimeError === null;
            }
        },
        startTimeError: function() {
            if (this.form.startTime === null || this.form.startTime.length < 5) {
                return null;
            } else {
                if (this.form.startTime.length > 5) {
                    return "Start time is too long";
                }

                const separator = this.form.startTime.substring(2, 3);
                if (separator !== ":") {
                    return "Please use a colon \":\" to separate hours and minutes";
                }

                const splits = this.form.startTime.split(":")

                if (splits.length !== 2 || splits[0].length !== 2 || splits[1].length !== 2) {
                    return "Incorrect start time format";
                }

                if (this.startDateState === true) {
                    const now = moment.tz(moment(), guessedTimezoneName);

                    if (now && !now.isValid()) {
                        return "Start Date/Time not a valid datetime";
                    }

                    if (now > this.startDateTime) {
                        return "Start Date/Time is in the past";
                    }
                }

                return null;
            }
        },
        firstNameState: function() {
            if (this.form.firstName === null) {
                return null;
            } else {
                return this.firstNameError === null;
            }
        },
        firstNameError: function () {
            if (this.form.firstName === null) {
                return null;
            } else {
                const testResult = this.nameRegEx.test(this.form.firstName);
                if (!testResult) {
                    return "First Name contains invalid characters"
                }
                return this.formErrors.firstName;
            }
        },
        lastNameState: function() {
            if (this.form.lastName === null) {
                return null;
            } else {
                return this.lastNameError === null;
            }
        },
        lastNameError: function() {
            if (this.form.lastName === null) {
                return null;
            } else {
                if (this.form.lastName.length < 2) {
                    return "Last Name must be at least 2 characters in length"
                }
                const testResult = this.nameRegEx.test(this.form.lastName);
                if (!testResult) {
                    return "Last Name contains invalid characters"
                }
                return this.formErrors.lastName;
            }
        },
        maxDateOfBirth: function() {
            const now = new Date()
            return new Date(now.getFullYear() - 21, now.getMonth(), now.getDate())
        },
        minDateOfBirth: function() {
            const now = new Date()
            return new Date(now.getFullYear() - 76, now.getMonth(), now.getDate())
        },
        dateOfBirthState: function () {
            if (this.form.dateOfBirth === null) {
                return null;
            } else {
                return this.dateOfBirthError === null;

            }
        },
        dateOfBirthError: function() {
            if (this.form.dateOfBirth === null) {
                return null;
            } else {
                if (this.form.dateOfBirth.length !== 10) {
                    return "Date of Birth is not in the correct format: DD/MM/YYYY";
                }

                const separator1 = this.form.dateOfBirth.substring(2, 3);
                const separator2 = this.form.dateOfBirth.substring(5, 6);
                if (separator1 !== "/" || separator2 !== "/") {
                    return "Please use a forward slash \"/\" to separate day, month and year";
                }

                const splits = this.form.dateOfBirth.split("/")

                if (splits.length !== 3 || splits[0].length !== 2 || splits[1].length !== 2 || splits[2].length !== 4) {
                    return "Start date is not in the correct format: DD/MM/YYYY";
                }

                let momentObj;
                try {
                    momentObj = this.parseDate("dateOfBirth", "DD/MM/YYYY");
                    if (momentObj && !momentObj.isValid()) {
                        return "Date of Birth is not a valid date";
                    }

                    if ((momentObj.isAfter(this.maxDateOfBirth) || momentObj.isBefore(this.minDateOfBirth))) {
                        return "You must be between 21 and 75 years old";
                    }
                } catch(e) {
                    return "Date of Birth is not valid";
                }

                return this.formErrors.dateOfBirth;
            }
        },
        postcodeState: function () {
            if (this.form.postcode === null) {
                return null;
            } else {
                return this.postcodeError === null;
            }
        },
        postcodeError: function () {
            if (this.form.postcode === null) {
                return null;
            } else {
                if (!this.postcodeRegEx.test(this.form.postcode)) {
                    return "Invalid Postcode"
                }

                return this.formErrors.postcode;
            }
        },
        foundAddressSelectList: function () {
            const otherList = this.foundAddresses.map(x => ({ value: x, text: x}));
            const placeholder = otherList.length > 0 ? "Please select an address" : "Click \"Lookup\" to find address";
            const initialList = [{ value: null, text: placeholder}];
            return initialList.concat(otherList);
        },
        addressState: function () {
            if (this.form.address === null) {
                if (!this.formErrors.address) {
                    return null;
                } else {
                    return false;
                }
            } else {
                return this.addressError === null;
            }
        },
        addressError: function() {
            if (this.form.address === null && this.formErrors.address === null) {
                return "You must select an address"
            }
            return this.formErrors.address;
        },
        formSubmitState: function() {

            return (
                this.vehicleRegState &&
                this.lengthOfCoverState &&
                this.startDateState &&
                this.startTimeState &&
                this.emailState &&
                this.emailConfirmState &&
                this.firstNameState &&
                this.lastNameState &&
                this.dateOfBirthState &&
                this.postcodeState &&
                this.addressState
            )
        }
    },
    mounted() {
        this.onReset();
    },
    methods: {
        async onSubmit(event) {
            event.preventDefault();

            // This is a hack to force reactivity on form and hence validation
            // because once populated, customer may not change a date/time value
            // and therefore no validation takes place if start time > now

            let now = moment.tz(moment(), guessedTimezoneName);
            let remainder = 5 - (now.minute() % 5);
            remainder = (remainder < 5) ? remainder + 5 : remainder;
            now = moment(now).add(remainder, "minutes");
            const startTimeInForm = now.format("HH:mm");

            // const startTimeInForm = this.form.startTime

            this.form.startTime = "BAD";
            const vm = this;

            await vm.$nextTick(function() {
                this.form.startTime = startTimeInForm;
            });

            await vm.$nextTick(function() {
                if (!vm.formSubmitState) {
                    return false;
                } else {
                    vm.quoteMessage = null;
                    vm.quoteStatus = null;

                    const quoteRequest = {
                        registration_number: vm.form.vehicleReg,
                        cover_start: vm.startDateTime.toISOString(),
                        cover_period_hours: vm.form.lengthOfCoverUnit === "hours" ? Number(vm.form.lengthOfCover) : Number(vm.form.lengthOfCover) * 24,
                        first_name: vm.form.firstName,
                        last_name: vm.form.lastName,
                        date_of_birth: vm.form.dateOfBirthIso,
                        postcode: vm.form.postcode,
                        address: vm.form.address,
                        email_address: vm.form.email,
                        receive_marketing: vm.form.marketing
                    }

                    vm.postQuote(quoteRequest);
                }
            });
        },
        setQuoteStatusAndMessage(status, message, sysMessage, sysMessageType="error") {
            this.quoteStatus = status;
            this.quoteMessage = message;
            if (sysMessage) {
                if (sysMessageType === "warn") {
                    if (this.$logger) {
                        this.$logger.warn(sysMessage);
                    }
                } else {
                    if (this.$logger) {
                        this.$logger.error(sysMessage);
                    }
                }
            }

            if (sysMessageType === "error" || sysMessageType === "warn") {
                this.updateQueryStringOnQuoteApiError();
            }

            this.scrollToElement("alert");
        },
        async postQuote(quoteData) {
            let response;

            try {

                PageLoader.init();
                response = await ApiService.postQuote(quoteData);
                if (response.status === 201) {
                    let quoteResponse = response.data;
                    if (quoteResponse.status === "OK") {
                        quoteResponse.quote.documents.map(doc => doc.url = doc.url.substring(doc.url.indexOf("v2/cdn/file") - 1));
                        this.$emit("quoteProvided", {quoteRequest: quoteData, quoteResponse: quoteResponse});
                    } else if (quoteResponse.status === "TOO_MANY_QUOTES") {
                        this.setQuoteStatusAndMessage(quoteResponse.status, quoteResponse.quote.errors.quote);
                    } else if (quoteResponse.status === "FAIL") {
                        this.setQuoteStatusAndMessage(response.data.status,"Quote submission error. See the errors below and adjust if possible", "ZixtyQuickQuote: FAIL received from quote endpoint", "warn");
                        this.setQuoteErrors(response.data.errors);
                    } else if (quoteResponse.status === "NO_QUOTES") {
                        this.setQuoteStatusAndMessage(response.data.status, response.data.errors.quote);
                    }
                } else if (response.status === 429) {
                    this.setQuoteStatusAndMessage("TOO_BUSY", "TOO_BUSY", "ZixtyQuickQuote: TOO_BUSY received from quote endpoint", "warn");
                } else if (response.status === 400) {
                    const message = response.data.message ? response.data.message : "Quote request failed. Please try again, or, do let us know via chat on our website";
                    let warning
                    if (response.data.errors) {
                        this.setQuoteSchemaErrors(response.data.errors);
                        warning = "ZixtyQuickQuote: SCHEMA_FAIL in quote endpoint";
                    } else {
                        warning = "ZixtyQuickQuote: Validation failure received from quote endpoint";
                    }
                    this.setQuoteStatusAndMessage(response.data.status || "FAIL", message, warning, "warn");

                } else if (response.status === 500) {
                    this.setQuoteStatusAndMessage("SYSTEM_ERROR", "Oops! Looks like we've got a quote gremlin. Please try again, or, do let us know via chat on our website", "ZixtyQuickQuote: SYSTEM_ERROR in quote endpoint");
                } else if (response.status > 500) {
                    this.setQuoteStatusAndMessage("INFRA_ERROR", "Oops! Looks like we've got some connectivity problems. Please try again shortly, or, do let us know via chat on our website", "ZixtyQuickQuote: SYSTEM_ERROR in quote endpoint");
                }

            } catch (error) {
                this.setQuoteStatusAndMessage("SYSTEM_ERROR", "System error", "ZixtyQuickQuote: Failed to submit quote request");
            } finally {
                PageLoader.remove();
            }
        },
        isQueryStringPriceNo() {
            return (this.$route.query || {}).price || "" === "no";
        },
        updateQueryStringOnQuoteApiError() {
            if (!this.isQueryStringPriceNo()) {
                this.$router.push({
                    path: "/",
                    query: {
                        price: "no",
                    },
                });
            }
        },
        onReset(event) {
            if (event) {
                event.preventDefault();
            }

            // reset query price
            if (this.isQueryStringPriceNo()) {
                this.$router.push({
                    path: "/",
                });
            }

            PageLoader.init();

            this.displayForm = false;

            let now = moment.tz(moment(), guessedTimezoneName);
            let remainder = 15 - (now.minute() % 15);
            if (remainder < 10) {
                remainder = remainder + 10;
            }
            now = moment(now).add(remainder, "minutes");

            this.form = Object.assign(
                {},
                this.form,
                {
                    email: null,
                    emailConfirm: null,
                    firstName: null,
                    lastName: null,
                    vehicleReg: null,
                    startDate: now.format("DD/MM/YYYY"),
                    startDateIso: now.format("YYYY-MM-DD"),
                    startTime: now.format("HH:mm"),
                    lengthOfCover: null,
                    lengthOfCoverUnit: "days",
                    dateOfBirthIso: null,
                    dateOfBirth: null,
                    postcode: null,
                    address: null,
                    marketing: false
                }
            );

            this.showQuoteAssumptions = false;
            this.quoteMessage = null;
            this.quoteStatus = null;
            this.formFieldFocusEvents = {};
            this.foundAddresses = [];

            this.formErrors = Object.assign(
                {},
                this.formErrors,
                {
                    lengthOfCover: null,
                    vehicleReg: null,
                    address: null,
                    startDate: null,
                    firstName: null,
                    lastName: null,
                    dateOfBirth: null,
                    postcode: null,
                    email: null,
                }
            );

            this.displayForm = true

            PageLoader.remove();

            this.$nextTick(function() {
                setTimeout(function() {
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }, 25);
            });
        },
        mapQuoteFieldToFormField(quoteField) {
            const mapping = {
                "registration_number": "vehicleReg",
                "cover_period_hours": "lengthOfCover",
                "cover_start": "startDate",
                "first_name": "firstName",
                "last_name": "lastName",
                "date_of_birth": "dateOfBirth",
                "postcode": "postcode",
                "address": "address",
                "email_address": "email",
            }
            return mapping[quoteField]
        },
        setQuoteErrors(errorObj) {
            for (const [key, value] of Object.entries(errorObj)) {
                this.$set(this.formErrors, this.mapQuoteFieldToFormField(key), value);
            }
        },
        setQuoteSchemaErrors(errorList) {
            for (const error of errorList) {
                this.$set(this.formErrors, this.mapQuoteFieldToFormField(error.name), error.message);
            }
        },
        lowerCaseFormatter(value) {
            return value.toLowerCase();
        },
        upperCaseFormatter(value) {
            return value.toUpperCase();
        },
        removeSecondsFormatter(value) {
            const splits = value.split(":")
            if (splits.length === 3) {
                return `${splits[0]}:${splits[1]}`
            } else {
                return value
            }
        },
        timeInputChanged(value) {
            this.form.startTime = this.removeSecondsFormatter(value);
        },
        parseDate(formField, format="YYYY-MM-DD") {
            if (this.form[formField] === null || this.form[formField].length < 10) {
                return null;
            }
            const dateObj = moment(this.form[formField], format);

            if (!dateObj.isValid) {
                throw new Error("Date Error");
            } else {
                return dateObj
            }
        },
        async getAddressesForPostcode() {

            let response;

            try {

                PageLoader.init();

                response = await ApiService.getAddresses(this.form.postcode);

                if (response.status === 200) {
                    const results = response.data;
                    if (results) {
                        results.addresses.forEach(element => this.foundAddresses.push(element.split(", ").filter(x => x.length > 0).join(", ")));
                    }
                    if (this.formErrors.address) {
                        this.$set(this.formErrors, "address", null);
                    }
                } else {
                    this.quoteStatus = "SYSTEM_ERROR"
                    this.quoteMessage = "Oops! Looks like we've had an issue getting addresses. Please try again, or, do let us know via chat on our website"
                    this.$set(this.formErrors, "address", "Failed to retrieve list of addresses");
                }

                PageLoader.remove();
            } catch (error) {
                this.$logger.error(`Error Getting Addresses = ${error}`);
                this.quoteStatus = "SYSTEM_ERROR"
                this.quoteMessage = "Oops! Looks like we've had an issue getting addresses. Please try again, or, do let us know via chat on our website"
                this.formErrors.address = "Failed to retrieve list of addresses. Please try again"
                PageLoader.remove();
            }

            PageLoader.init();

            PageLoader.remove();
        },
        visibilityChanged (isVisible, entry) {
            this.isLarge = isVisible
        },
        startDateChange(value) {
            this.formErrors.startDate = null;
            if (value && value.length === 10) {
                this.form.startDateIso = `${value.substring(6, 10)}-${value.substring(3, 5)}-${value.substring(0, 2)}`
            } else {
                this.form.startDateIso = null;
            }
        },
        startDateIsoChange(value) {
            this.form.startDate = `${value.substring(8, 10)}/${value.substring(5, 7)}/${value.substring(0, 4)}`
        },
        dateOfBirthChange(value) {
            this.formErrors.dateOfBirth = null;
            if (value && value.length === 10) {
                this.form.dateOfBirthIso = `${value.substring(6, 10)}-${value.substring(3, 5)}-${value.substring(0, 2)}`
            } else {
                this.form.dateOfBirthIso = null;
            }
        },
        dateOfBirthIsoChange(value) {
            this.form.dateOfBirth = `${value.substring(8, 10)}/${value.substring(5, 7)}/${value.substring(0, 4)}`
        },
        vehicleRegChange(value) {
            this.formErrors.vehicleReg = null;
        },
        lengthOfCoverChange(value) {
            this.formErrors.lengthOfCover = null;
        },
        startTimeChange(value) {
            this.formErrors.startDate = null;
        },
        emailChange(value) {
            this.formErrors.email = null;
        },
        firstNameChange(value) {
            this.formErrors.firstName = null;
        },
        lastNameChange(value) {
            this.formErrors.lastName = null;
        },
        postcodeChange(value) {
            this.formErrors.postcode = null;
            if (this.foundAddresses.length > 0) {
                this.foundAddresses = []
            }
        },
        addressChange(value) {
            this.formErrors.address = null;
        },
        scrollToElement(refName) {
            const el = this.$refs[refName];
            if (el) {
                el.scrollIntoView({behavior: "smooth"});
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.help-icon {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: 1.2rem;
    line-height: 1.42857;
}
#input-postcode {
    border-radius: 5px;
}

::v-deep {
    label, p, small {
        color: #595959;
        font-weight: 400;
    }
    input {
        padding: 20px 15px;
        &::placeholder {
            color: #999797;
        }
    }
}

#input-group-loc {
    #input-length-of-cover {
        border-radius: 5px;
    }
    &::v-deep #input-length-of-cover-type {
        border-radius: 5px;
        height: 42px;
        border: 1px solid #ced4da;
        margin-left: 10px;
        &:hover {

        }
    }
}

#input-group-start-date,
#input-group-date-of-birth {
    #input-start-date,
    #input-date-of-birth {
        border-radius: 5px;
    }
    &::v-deep .b-form-datepicker button,
    &::v-deep .b-form-datepicker button {
        background: #fff;
        border-radius: 5px;
        height: 42px;
        border: 1px solid #ced4da;
        color: #595959;
        margin-left: 10px;
    }
}
</style>
