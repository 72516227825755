import * as Sentry from "@sentry/browser";
const Transport = require("winston-transport");

export default class SentryTransporter extends Transport {
    constructor(opts) {
        super(opts);
    }

    log(info, _callback) {

        const env = process.env.VUE_APP_ENVIRONMENT;
        const level = info["level"];
        const message = info["message"];

        const mappings = {
            "info": "log",
            "warn": "warning",
            "error": "error"
        };

        console.log(level);
        if (["warn", "error"].includes(level) && (!env.includes("local") || process.env.VUE_APP_SEND_TO_SENTRY_ALWAYS === "true") ) {
            const mappedLevel = mappings[level];
            Sentry.captureMessage(message, mappedLevel);
        }

        _callback();
    }
}
