import moment from "moment-timezone";

export const MI2KM = 1609.344;
export const M2MI = 0.00062137;
export const M2YD = 1.0936;
export const guessedTimezoneName = moment.tz.guess(true);

export function dispDateTime(datetime, showTime = false, hideTodaysDate = true) {
    if (datetime == null || datetime == "") {
        return ""
    }
    const finalDateTimeFormat = "DD/MM/YYYY HH:mm:ss";
    const finalDateFormat = "DD/MM/YYYY";
    const dateFormat = "YYYYMMDD";
    const timeFormat = "HH:mm:ss";

    let msgDateTime = moment.tz(datetime, guessedTimezoneName);
    let nowDateTime = moment.tz(moment(), guessedTimezoneName);
    if (hideTodaysDate && msgDateTime.format(dateFormat) == (nowDateTime.format(dateFormat))) {
        // If the date of message is same as today, then print only the time
        return msgDateTime.format(timeFormat);
    } else if (showTime) {
        return msgDateTime.format(finalDateTimeFormat);
    } else {
        return msgDateTime.format(finalDateFormat);
    }
}

export function getTodayInDisplayFormat(showTime=false) {
    let nowDateTime = moment.tz(moment(), guessedTimezoneName);
    if (showTime) {
        return nowDateTime.format("DD/MM/YYYY HH:mm:ss");
    } else {
        return nowDateTime.format("YYYY-MM-DD");
    }
}

export function getNowMomentInTimezone() {
    let nowDateTime = moment.tz(moment(), guessedTimezoneName);
    return nowDateTime;
}

// Pass in a date in YYYY-MM-DD and get back a UTC full datetime with timezone
export function getDateTimeInUtcFromNaiveDate(dateText, timeText=null) {
    console.log(guessedTimezoneName);
    let dateTime = null;
    if (timeText == null) {
        dateTime = moment.tz(moment(dateText, "YYYY-MM-DD"), guessedTimezoneName);
    } else {
        dateTime = moment.tz(moment(`${dateText} ${timeText}`, "YYYY-MM-DD HH:mm:ss"), guessedTimezoneName);
        console.log(dateTime)
    }
    // now convert to UTC
    return dateTime.utc();
}

export function getDateTimeInSecondsFromString(stringDateTime) {
    try {
        const dateTime = moment.tz(moment(stringDateTime), guessedTimezoneName);
        const timeInSecs = Math.round(dateTime.valueOf() / 1000);
        // console.log(`Setting datetime = ${timeInSecs}`);
        return timeInSecs;
    } catch (error) {
        console.error(error);
    }
}

export function getLocalDateTimeInFromNaiveString(stringDateTime, inputTimeZone="UTC") {
    try {
        const dateTime = moment.tz(stringDateTime, inputTimeZone).tz(guessedTimezoneName, true);
        return dateTime;
    } catch (error) {
        console.error(error);
    }
}

export function getNowInSeconds() {
    try {
        const dateTime = moment.tz(moment(), guessedTimezoneName);
        const timeInSecs = Math.round(dateTime.valueOf() / 1000);
        // console.log(`Setting datetime = ${timeInSecs}`);
        return timeInSecs;
    } catch (error) {
        console.error(error);
    }
}

// Pass in a date in YYYY-MM-DD and get back a UTC full datetime of last second of that day with timezone
export function getLastSecondOfDayInUtcfromNaiveDate(dateText) {
    let dateTime = moment.tz(moment(dateText, "YYYY-MM-DD"), guessedTimezoneName);
    // now add one day
    let nextDay = dateTime.add("1","days");
    // now take away 1 second
    let lastSecondDateTime = nextDay.subtract("1","seconds");
    // now convert to UTC
    return lastSecondDateTime.utc();
}

// Utility that should only be used in testing to block main thread
export function wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
        end = new Date().getTime();
    }
}

