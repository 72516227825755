<template>
    <div>
        <div class="my-3">
            <h2 class="text-white font-weight-light">
                {{ title }}
            </h2>
        </div>
        <div>
            <div v-for="link in links" :key="link.text" class="my-2">
                <b-link :href="link.href" target="_blank" rel="noopener" class="text-green-dark">
                    {{ link.text }}
                </b-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterLinksList",
    props: {
        title: {
            type: String,
            required: true,
            default: ""
        },
        links: {
            type: Array,
            required: true,
            default: () => ([
                //
            ]),
        },
    },
    data() {
        return {
            //
        };
    },
};
</script>

<style lang="scss" scoped>
    a {
        font-size: 1.35em;
    }
</style>
