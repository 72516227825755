<template>
    <div v-if="quoteStatus==='OK'" class="row">
        <div class="col-12">
            <b-form-group
                :id="getGroupId"
                :label="fieldLabel"
                :label-for="getInputId"
                :label-cols="labelCols"
                :label-cols-md="labelColsMd"
                :label-cols-lg="labelColsLg"
                label-class="font-weight-400"
                :label-size="sizing"
            >
                <b-form-textarea
                    v-if="splitLine"
                    :id="getInputId"
                    v-model="value"
                    readonly
                    plaintext
                    disabled
                    :rows="splits.length"
                    :class="{
                        'font-weight-500': true,
                        'data-hjs-allow': hjsAllow,
                    }"
                    no-resize
                />
                <b-form-input
                    v-else
                    :id="getInputId"
                    v-model="value"
                    readonly
                    plaintext
                    disabled
                    :class="{
                        'font-weight-500': true,
                        'data-hjs-allow': hjsAllow,
                    }"
                />
            </b-form-group>
        </div>
    </div>
</template>

<script>

export default {
    name: "DisplayQuoteField",
    props: {
        quoteStatus: {
            type: String,
            required: true,
            default: "UNKNOWN"
        },
        sizing: {
            type: String,
            required: true,
            default: "md"
        },
        fieldName: {
            type: String,
            required: true,
            default: "md"
        },
        fieldLabel: {
            type: String,
            required: true,
            default: ""
        },
        fieldValue: {
            type: String,
            required: true,
            default: ""
        },
        splitLine: {
            type: String,
            required: false,
            default: ""
        },
        labelCols: {
            type: Number,
            default: 12,
        },
        labelColsMd: {
            type: Number,
            default: 5,
        },
        labelColsLg: {
            type: Number,
            default: 4,
        },
        hjsAllow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: null,
            splits: []
        };
    },
    computed: {
        getGroupId: function() {
            return `display-group-${this.fieldName}`
        },
        getInputId: function() {
            return `display-${this.fieldName}`
        },
    },
    mounted() {
        this.value = this.fieldValue;
        if (this.value && this.splitLine) {
            this.splits = this.value.split(this.splitLine);
            this.value = this.splits.join("\r\n");
        }
    },
    methods: {
        //
    }
};
</script>

<style lang='scss' scoped>
.form-group {
    margin-bottom: 0.2rem !important;
}
::v-deep {
    .form-control-plaintext {
        opacity: initial !important;
        color: #212022;
    }
}
</style>
